import { useState, Dispatch, SetStateAction } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import {
    Grid,
    Typography,
    Button,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControlLabel,
    Tooltip,
    Switch,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import UndoIcon from '@material-ui/icons/Undo';
import ConfirmIcon from '@material-ui/icons/Done';
import RemoveUserIcon from '@material-ui/icons/PersonAddDisabled';
import ExtraMemberIcon from '@material-ui/icons/PersonAdd';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SaveIcon from '@material-ui/icons/Save';
import IdoIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles, Theme } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IExternalGroup from '../../../models/IExternalGroup';
import IGroupMember from '../../../models/IGroupMember';
import GroupMembersList from './GroupMembersList';
import CustomLabelsHelper from '../../../helpers/CustomLabelsHelper';

dayjs.locale('fr');

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: '20px 15px',
    },
    hiddenGroupMember: {
        color: '#C0C0C0',
        fontStyle: 'italic'
    },
    bottomActions: {
        marginTop: 20,
        marginBottom: 20,
    },
    teacher: {
        fontWeight: 'bold',
        color: '#6F6F6F'
    },
    disabled: {
        color: '#C0C0C0'
    },
    actionButton: {
        marginLeft: 20,
        marginBottom: 5
    },
    noMembers: {
        fontWeight: 'bold',
        marginTop: 20,
        marginBottom: 20
    },
    switchIsManaged: {
        fontWeight: 'bold',
        color: '#000'
    },
    switchIsUnmanaged: {
        fontWeight: 'bold',
        color: '#C0C0C0'
    }
}));

interface ExternalGroupTabPanelProps {
    children?: React.ReactNode;
    id: string;
    instanceType: string | null;
    firstTime: boolean;
    bookId: string;
    value: IExternalGroup | undefined;
    groupMembers: IGroupMember[];
    setGroupMembers: Dispatch<SetStateAction<IGroupMember[]>>;
    groupMembersLoading: boolean;
    canSave: boolean;
    setGroupIsManaged: (groupId: string, managed: boolean) => void;
    setGroupMemberIsIgnored: (groupId: string, userId: string, hidden: boolean) => void;
    save: (goNext: boolean) => void;
    cancel: () => void;
    goNext: () => void;
}

const ExternalGroupTabPanel = (props: ExternalGroupTabPanelProps) => {
    const classes = useStyles();
    const { value, id, instanceType, firstTime, bookId, groupMembers, groupMembersLoading, setGroupMembers, setGroupIsManaged, setGroupMemberIsIgnored, canSave, save, goNext, cancel, ...other } = props;
    const isCurrentTab = value && value.Id === id;
    const [openMembersListDialog, setOpenMembersListDialog] = useState<boolean>(false);
    const [selectedMembers, setSelectedMembers] = useState<IGroupMember[]>();
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);

    const curStudentLabelLowered = CustomLabelsHelper.getStudentLabelLowerCase(instanceType);
    const curTeacherLabelLowered = CustomLabelsHelper.getTeacherLabelLowerCase(instanceType);
    const cannotRemoveTeacherMessage = `Vous ne pouvez pas retirer un ${curStudentLabelLowered} ajouté manuellement`;

    const handleMembersListDialogOpen = () => {
        setOpenMembersListDialog(true);
    };

    const handleMembersListDialogClose = () => {
        setOpenMembersListDialog(false);
        setSelectedMembers(undefined);
    };

    const handleMembersListDialogConfirm = () => {
        if (selectedMembers && selectedMembers.length > 0) {
            // add // need to do distinct
            const currentMembersIds = groupMembers.map((m) => m.Id);
            const uniqueSelectedMembers = selectedMembers.filter((s) => currentMembersIds.indexOf(s.Id) < 0).map((m) => {
                m.Extra = true;
                return m;
            });
            const validNewGroupMembers = [...groupMembers, ...uniqueSelectedMembers];
            setGroupMembers(validNewGroupMembers);
            setIsChanged(true);
        }
        setSelectedMembers(undefined);
        setOpenMembersListDialog(false);
    };

    const handleConfirmationDialogClose = () => {
        setOpenConfirmationDialog(false);
    }

    const handleSaveConfirmationDialogClose = () => {
        save(false);
        setOpenConfirmationDialog(false);
    };

    const handleSaveAndGoNextConfirmationDialogClose = () => {
        save(true);
        setIsChanged(false);
        setOpenConfirmationDialog(false);
    };

    const handleCancel = () => {
        cancel();
        setIsChanged(false);
    }

    const handleSave = () => {
        if (value && ((value.ManagedId && !value.IsManaged) || (!value.ManagedId && value.IsManaged))) {
            setOpenConfirmationDialog(true);
        } else {
            save(false);
            setIsChanged(false);
        }
    };

    const handleGoNext = () => {
        goNext();
    };

    const handleRemoveGroupMember = (member: IGroupMember) => {
        // add // need to do distinct
        const idxMember = groupMembers.findIndex((m) => m.Id === member.Id);
        if (idxMember >= 0) {
            setGroupMembers([
                ...groupMembers.slice(0, idxMember),
                ...groupMembers.slice(idxMember + 1)
            ]);
            setIsChanged(true);
        }
    };

    return (<>
        <div
            role="tabpanel"
            hidden={!isCurrentTab}
            id={`scrollable-auto-tabpanel-${id}`}
            aria-labelledby={`scrollable-auto-tab-${id}`}
            style={{ marginTop: 0, marginBottom: 20, border: '1px solid #D3D3D3' }}
            {...other}
        >
            {isCurrentTab && value && (
                <Box p={3}
                    className={classes.root}>
                    <FormControlLabel
                        control={<Switch
                            defaultChecked={value.IsManaged ?? false}
                            onChange={(ev, checked) => {
                                setGroupIsManaged(value.Id, checked);
                                setIsChanged(true);
                            }}
                        />}
                        label="Gérer cette classe"
                        classes={{
                            label: value.IsManaged ? classes.switchIsManaged : classes.switchIsUnmanaged
                        }}
                        aria-label="Gestion du groupe"
                        name="groupManaged"
                    />
                    <div style={{ marginTop: 20 }}>
                        <Typography style={{ color: 'green' }}>Tous les {curStudentLabelLowered}s de "{value.Name}" (classe / groupe issu de l'annuaire établissement) seront ajoutés au fur et à mesure de leur connexion.</Typography>
                        <Typography>Pour apparaitre dans la liste ci-dessous, les {curStudentLabelLowered}s doivent s’être connectés au moins une fois à leur <img src="/_layouts/imanuel/css/images/logo_imanuelplus_2.png" alt="Logo i-Manuel" title="i-Manuel" style={{ verticalAlign: "bottom" }} /></Typography>
                        {value.ManagedId && (<div style={{ display: 'none' }}>{`Classe existante, id = ${value.ManagedId}, extid = ${value.ExtId}`}</div>)}
                        {!groupMembersLoading && value.IsManaged && (
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="stretch"
                                className={classes.bottomActions}
                            >
                                <Button
                                    className={classes.actionButton}
                                    disabled={!canSave}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    onClick={handleMembersListDialogOpen}>Ajouter un {curStudentLabelLowered}</Button>
                            </Grid>
                        )}
                        {!groupMembersLoading && (<>
                            <TableContainer>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={150} className={!value.IsManaged ? classes.disabled : undefined}>Nom</TableCell>
                                            <TableCell className={!value.IsManaged ? classes.disabled : undefined}>Prénom</TableCell>
                                            <TableCell width={80} className={!value.IsManaged ? classes.disabled : undefined}>Profil</TableCell>
                                            <TableCell width={150} align="center" className={!value.IsManaged ? classes.disabled : undefined}>Date 1ere connexion</TableCell>
                                            <TableCell width={150} align="center" className={!value.IsManaged ? classes.disabled : undefined}>Date fin de licence</TableCell>
                                            <TableCell width={50}></TableCell>
                                            <TableCell width={100} align="center" className={!value.IsManaged ? classes.disabled : undefined}>Masquer/montrer</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groupMembers.filter(gm => gm.RoleLabel == "élève").map((member) => (
                                            <TableRow key={member.Id}>
                                                <TableCell component="th" scope="row" className={member.Ignored || !value.IsManaged ? classes.hiddenGroupMember : undefined}>{member.LastName}</TableCell>
                                                <TableCell className={member.Ignored || !value.IsManaged ? classes.hiddenGroupMember : undefined}>{member.FirstName}</TableCell>
                                                <TableCell className={member.Ignored || !value.IsManaged ? classes.hiddenGroupMember : undefined} style={{ textTransform: 'capitalize' }}>{curStudentLabelLowered}</TableCell>
                                                <TableCell className={member.Ignored || !value.IsManaged ? classes.hiddenGroupMember : undefined} align="center">{member.CreationDate ? dayjs(member.CreationDate).format('DD/MM/YYYY') : '-'}</TableCell>
                                                <TableCell className={member.Ignored || !value.IsManaged ? classes.hiddenGroupMember : undefined} align="center">{member.ExpirationDate ? dayjs(member.ExpirationDate).format('DD/MM/YYYY') : '-'}</TableCell>
                                                <TableCell className={member.Ignored || !value.IsManaged ? classes.hiddenGroupMember : undefined} align="center">
                                                    {member.Extra && (<ExtraMemberIcon titleAccess={"Elève ajouté manuellement"} />)}
                                                    {member.Login && member.Login.indexOf('.IDO.') > 0 && (<IdoIcon titleAccess="IDO" />)}</TableCell>
                                                <TableCell align="center">
                                                    {member.Extra && (
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            disabled={member.Ignored}
                                                            startIcon={<RemoveUserIcon />}
                                                            onClick={() => handleRemoveGroupMember(member)}>Retirer</Button>
                                                    )}
                                                    {!member.Extra && !member.Ignored && (
                                                        <VisibilityIcon onClick={() => {
                                                            setGroupMemberIsIgnored(value.Id, member.Id, true);
                                                            setIsChanged(true);
                                                        }} />)}
                                                    {!member.Extra && member.Ignored && (
                                                        <VisibilityOffIcon onClick={() => {
                                                            setGroupMemberIsIgnored(value.Id, member.Id, false);
                                                            setIsChanged(true);
                                                        }} />)}

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {groupMembers.filter(gm => gm.RoleLabel == "professeur").map((member) => (
                                            <TableRow key={member.Id}>
                                                <TableCell component="th" scope="row" className={classes.teacher}>{member.LastName}</TableCell>
                                                <TableCell className={classes.teacher}>{member.FirstName}</TableCell>
                                                <TableCell className={classes.teacher} style={{ textTransform: 'capitalize' }}>{curTeacherLabelLowered}</TableCell>
                                                <TableCell className={classes.teacher} align="center">-</TableCell>
                                                <TableCell className={classes.teacher} align="center">{member.ExpirationDate ? dayjs(member.ExpirationDate).format('DD/MM/YYYY') : '-'}</TableCell>
                                                <TableCell align="center">
                                                    {member.Extra && (<ExtraMemberIcon titleAccess={ `${CustomLabelsHelper.getTeacherLabel(instanceType)} ajouté manuellement`} />)}
                                                    {member.Login && member.Login.indexOf('.IDO.') > 0 && (<IdoIcon titleAccess="IDO" />)}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {member.Extra && (
                                                        <Tooltip title={cannotRemoveTeacherMessage} aria-label={cannotRemoveTeacherMessage}>
                                                            <div>
                                                                <Button
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    disabled={true}
                                                                    startIcon={<RemoveUserIcon />}>Retirer</Button>
                                                            </div>
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="stretch"
                                className={classes.bottomActions}
                            >
                                <Button
                                    className={classes.actionButton}
                                    disabled={!isChanged}
                                    color="secondary"
                                    startIcon={<UndoIcon />}
                                    onClick={handleCancel}>Annuler</Button>
                                <Button
                                    className={classes.actionButton}
                                    disabled={!canSave || !isChanged}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<SaveIcon />}
                                    onClick={handleSave}>Sauvegarder</Button>
                            </Grid>
                        </>)}
                        {groupMembersLoading && (
                            <div style={{ width: '100%', marginTop: 30, marginBottom: 30, textAlign: 'center' }}>
                                <CircularProgress color="secondary" />
                            </div>
                        )}
                    </div>
                    <Dialog
                        open={openConfirmationDialog}
                        onClose={handleConfirmationDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{`Confirmation de la sauvegarde`}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {value.ManagedId && !value.IsManaged && (<>En confirmant, vous acceptez de ne plus gérer cette classe que vous aviez préalablement activée.</>)}
                                {!value.ManagedId && value.IsManaged && (<>Une fois la classe créée, vous pouvez la gérer et effectuer le suivi de ses {curStudentLabelLowered}s.</>)}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                className={classes.actionButton}
                                onClick={handleConfirmationDialogClose}
                                color="primary">Annuler</Button>
                            {!firstTime && (<Button
                                className={classes.actionButton}
                                color="secondary"
                                variant="contained"
                                startIcon={<ConfirmIcon />}
                                autoFocus
                                onClick={handleSaveConfirmationDialogClose}>Confirmer</Button>)}
                            {firstTime && (<Button
                                className={classes.actionButton}
                                color="secondary"
                                variant="contained"
                                startIcon={<ConfirmIcon />}
                                autoFocus
                                onClick={handleSaveAndGoNextConfirmationDialogClose}>Confirmer et passer au suivant</Button>)}
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openMembersListDialog}
                        onClose={handleMembersListDialogClose}
                        aria-labelledby="alert-dialog-title"
                        fullWidth
                        maxWidth={"lg"}
                    >
                        <DialogTitle id="alert-dialog-title">Sélection des {curStudentLabelLowered}s</DialogTitle>
                        <DialogContent>
                            <div style={{ marginBottom: 15, fontSize: '14px' }}><b>Seuls les {curStudentLabelLowered}s qui se sont connectés au moins une fois à leur manuel apparaissent dans la liste ci-dessous.</b></div>
                            <GroupMembersList
                                bookId={bookId}
                                setSelectedMembers={setSelectedMembers}
                                disabledMemberIds={groupMembers.map((m) => m.Id)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                className={classes.actionButton}
                                color="primary"
                                onClick={handleMembersListDialogClose}>Annuler</Button>
                            <Button
                                className={classes.actionButton}
                                color="secondary"
                                variant="contained"
                                autoFocus
                                startIcon={<ConfirmIcon />}
                                onClick={handleMembersListDialogConfirm}>Confirmer</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            )}
        </div>

        {firstTime && isCurrentTab && value && (
            <Button
                disabled={!canSave}
                variant="contained"
                color="secondary"
                endIcon={<NavigateNextIcon />}
                onClick={handleGoNext}>Passer à la classe suivante</Button>
        )}
    </>);
}

export default ExternalGroupTabPanel;
