import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import BookResourcesLibrary from './mainComponents/bookResourcesLibrary/BookResourcesLibrary';
import reportWebVitals from './reportWebVitals';
import GroupsDashboard from './mainComponents/groupsDashboard/GroupsDashboard';

window.addEventListener("DOMContentLoaded", (event) => {
  console.log("DOM entièrement chargé et analysé");
  if (document.getElementById('root')) {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }

  if (document.getElementById('book-resources-library')) {
    const elt = document.getElementById('book-resources-library');
    const instanceType = (elt && elt.hasAttribute("data-instance-type")) ? elt.getAttribute("data-instance-type") : "";
    ReactDOM.render(
      <React.StrictMode>
        <BookResourcesLibrary instanceType={instanceType} />
      </React.StrictMode>,
      elt
    );
  }

  if (document.getElementById('groups-dashboard')) {
    const elt = document.getElementById('groups-dashboard');
    const instanceType = (elt && elt.hasAttribute("data-instance-type")) ? elt.getAttribute("data-instance-type") : "";
    ReactDOM.render(
      <React.StrictMode>
        <GroupsDashboard instanceType={instanceType} />
      </React.StrictMode>,
      elt
    );
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
