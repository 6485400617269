export default class CustomLabelsHelper {
    public static getStudentLabel = (instanceType: string | null) : string => {
        return (instanceType === "Campus") ? "Apprenant" : "Elève";
    }

    public static getStudentLabelLowerCase = (instanceType: string | null) : string => {
        return (instanceType === "Campus") ? "apprenant" : "élève";
    } 
    
    public static getTeacherLabel = (instanceType: string | null) : string => {
        return (instanceType === "Campus") ? "Formateur" : "Enseignant";
    }

    public static getTeacherLabelLowerCase = (instanceType: string | null) : string => {
        return (instanceType === "Campus") ? "formateur" : "enseignant";
    }
}